import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["rowAuthorization", "contractSelect"];

  removeRow(event) {
    document.querySelector(".modal .overlay").classList.add("d-none");
    document.querySelector(`tr[data-id="${event.params.id}"]`).remove();

    if (this.rowAuthorizationTargets.length === 0) {
      document.querySelector(
        "div.table-container",
      ).innerHTML = `<div class="alert bg-gray-dark"><span>Nenhum associação disponivel</span></div>`;
    }

    toastr.success(
      "Associação excluida com sucesso",
      "Atribuições de contratos",
    );
  }

  handleContracts(event) {
    const companyId = event.target.value;
    const notAuthorize = event.params.notAuthorize?.split(",") || [];

    const queryParams = notAuthorize
      .map((na) => `q[id_not_in][]=${na}`)
      .join("&");
    event.target.setAttribute("disabled", "true");

    const affiliatable_id = document.querySelector("#affiliatable_id");
    affiliatable_id.innerHTML = "";
    $(affiliatable_id).trigger("chosen:updated");

    const url = `/administration/contracts.json?per=100&q[company_id_eq]=${companyId}&${queryParams}`;

    fetch(url, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    })
      .then((resp) => {
        return resp.json();
      })
      .then((data) => {
        data.forEach((contract) => {
          const element = document.createElement("option");
          element.value = contract.id;
          element.innerHTML = `${contract.identifier} (${
            contract.category || ""
          })`;
          affiliatable_id.appendChild(element);
        });

        setTimeout(() => {
          $(affiliatable_id).trigger("chosen:updated");
        }, 100);

        $(affiliatable_id).trigger("chosen:updated");
      })
      .catch((error) => {
        console.error("Erro ao carregar contratos:", error);
      })
      .finally(() => {
        event.target.removeAttribute("disabled");
      });
  }
}
